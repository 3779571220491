<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 작업기간 -->
          <c-datepicker
            :range="true"
            defaultStart="-2y"
            defaultEnd="2M"
            label="공포기간"
            name="promulgationPeriod"
            v-model="searchParam.promulgationPeriod"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="법령 목록"
      tableId="lawpop"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :isExcelDown="false"
      :isFullScreen="false"
      :columnSetting="false"
      :selection="popupParam.type"
      rowKey="mdmLawId"
      @linkClick="linkClick"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="props.row.lawLink">
          <q-btn
            class="tableinnerBtn"
            flat
            icon="launch"
            align="center"
            color="blue-6"
            label=""
            @click.stop="linkClick(props, col)" />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'law-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      searchParam: {
        promulgationPeriod: [],
        lawCheck: true,
      },
      grid: {
        columns: [
          {
            name: 'lawSerialNum',
            field: 'lawSerialNum',
            label: '법령일련번호',
            align: 'center',
            sortable: false,
            style: 'width:80px',
          },
          {
            name: 'curHistroyCode',
            field: 'curHistroyCode',
            label: '현행연혁코드',
            align: 'center',
            sortable: false,
            style: 'width:80px',
          },
          {
            name: 'lawNameKor',
            field: 'lawNameKor',
            label: '법령명한글',
            align: 'left',
            sortable: false,
            style: 'width:200px',
          },
          {
            name: 'lawNameAbb',
            field: 'lawNameAbb',
            label: '법령약칭명',
            align: 'left',
            sortable: false,
            style: 'width:120px',
          },
          {
            name: 'promulgationDate',
            field: 'promulgationDate',
            label: '공포일자',
            align: 'center',
            sortable: false,
            style: 'width:80px',
          },
          {
            name: 'revisiontypename',
            field: 'revisiontypename',
            label: '제개정구분명',
            align: 'center',
            sortable: false,
            style: 'width:80px',
          },
          {
            name: 'competentMinistriesName',
            field: 'competentMinistriesName',
            label: '소관부처명',
            align: 'center',
            sortable: false,
            style: 'width:120px',
          },
          {
            name: 'lawClassName',
            field: 'lawClassName',
            label: '법령구분명',
            align: 'center',
            sortable: false,
            style: 'width:100px',
          },
          {
            name: 'enforcementDate',
            field: 'enforcementDate',
            label: '시행일자',
            align: 'center',
            sortable: false,
            style: 'width:80px',
          },
          // {
          //   name: 'lawTypeFlag',
          //   field: 'lawTypeFlag',
          //   label: '자법타법여부',
          //   align: 'center',
          //   sortable: false,
          //   style: 'width:80px',
          // },
          {
            name: 'custom',
            field: 'custom',
            label: '법령상세링크',
            align: 'center',
            sortable: false,
            style: 'width:80px',
            type: 'custom'
          },
        ],
        data: [],
        height: '700px'
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.law.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(props, col) {
      window.open('http://www.law.go.kr' + props.row.lawLink, '법규', 'width=1200, height=700');
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '법령을 선택하세요.', // 법령을 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
